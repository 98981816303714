import { AsyncPipe, DatePipe } from '@angular/common'
import { Component, inject } from '@angular/core'
import {
    FormControl,
    NonNullableFormBuilder,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms'
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask'
import { ToastrService } from 'ngx-toastr'
import { map, Observable, Subject, takeUntil } from 'rxjs'
import { SharedModule } from '../../../../legacy/shared/shared.module'
import { client_base_options, invoicing_options } from '../../../../shared/static'
import { ProfileService } from '../../services/profile.service'
import { Profile } from '../../types/profile.types'

interface UpdateProfileForm {
    phone_number: FormControl<string>
    client_base?: FormControl<string>
    monthly_billing?: FormControl<string>
}

@Component({
    selector: 'app-profile',
    standalone: true,
    imports: [
        AsyncPipe,
        NgxMaskDirective,
        ReactiveFormsModule,
        NgxMaskPipe,
        DatePipe,
        SharedModule,
    ],
    providers: [provideNgxMask()],
    templateUrl: './profile.component.html',
    styleUrl: './profile.component.scss',
})
export class ProfileComponent {
    private profileService = inject(ProfileService)
    private formBuilder = inject(NonNullableFormBuilder)
    private toastr = inject(ToastrService)

    client_base_list = client_base_options
    invoicing_options = invoicing_options

    protected isUpdatingProfile = false
    protected isRequestingTerms = false

    protected profile$!: Observable<Profile>
    protected isDisabled = true
    private _onDestroy$ = new Subject()

    protected updateProfileForm = this.formBuilder.group<UpdateProfileForm>({
        phone_number: new FormControl({ value: '', disabled: true }, { nonNullable: true }),
    })

    ngOnInit() {
        this.profile$ = this.profileService.profile
        this.subscribeForm()
        this.profileService.getProfileData()
    }

    private subscribeForm() {
        this.profile$
            .pipe(
                takeUntil(this._onDestroy$),
                map(profie => {
                    this.updateProfileForm.controls.phone_number.setValue(profie.user.phone_number)
                    if (profie.isOwner) {
                        this.updateProfileForm.addControl(
                            'client_base',
                            new FormControl(
                                { value: profie.partner?.client_base ?? '', disabled: true },
                                {
                                    nonNullable: true,
                                    validators: [Validators.required],
                                },
                            ),
                        )
                        this.updateProfileForm.addControl(
                            'monthly_billing',
                            new FormControl(
                                { value: profie.partner?.monthly_billing ?? '', disabled: true },
                                {
                                    nonNullable: true,
                                    validators: [Validators.required],
                                },
                            ),
                        )
                    }
                }),
            )
            .subscribe()
    }

    protected changeEditForm() {
        if (this.isDisabled) {
            this.updateProfileForm.enable()
        } else {
            this.updateProfileForm.disable()
            this.isUpdatingProfile = false
        }
        this.isDisabled = !this.isDisabled
    }

    protected onSubmit() {
        if (this.updateProfileForm.valid) {
            this.isUpdatingProfile = true
            const result = this.updateProfileForm.value
            this.profileService.updateProfile(result, () => this.changeEditForm())
        } else {
            this.toastr.error('Formulário Inválido')
        }
    }

    getGender(gender?: string) {
        if (gender == 'male') return 'Masculino'
        if (gender == 'female') return 'Feminino'

        return 'Prefiro Não Declarar'
    }

    requestTermsAndConditions() {
        this.isRequestingTerms = true

        this.profileService.requestTermsAndConditions(() => {
            this.isRequestingTerms = false
        })
    }

    ngOnDestroy(): void {
        this._onDestroy$.next({})
    }
}
